import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jws sign`}</strong>{` -- create a signed JWS data structure`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jws sign [- | <filename>]
[--alg=<algorithm>] [--jku=<jwk-url>] [--jwk] [--typ=<type>]
[--cty=<content-type>] [--key=<file>] [--jwks=<jwks>] [--kid=<kid>]
[--password-file=<file>] [--x5c-cert=<file>] [--x5c-key=<file>]
[--x5t-cert=<file>] [--x5t-key=<file>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto jws sign`}</strong>{` generates a signed JSON Web Signature (JWS) by
computing a digital signature or message authentication code for an arbitrary
payload. By default, the payload to sign is read from STDIN and the JWS will
be written to STDOUT.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto jws`}</strong>{`.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--alg`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`, `}<strong parentName="p">{`--algorithm`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`
The signature or MAC algorithm to use. Algorithms are case-sensitive strings
defined in RFC7518. The selected algorithm must be compatible with the key
type. This flag is optional. If not specified, the "alg" member of the JWK is
used. If the JWK has no "alg" member then a default is selected depending on
the JWK key type. If the JWK has an "alg" member and the "alg" flag is passed
the two options must match unless the '--subtle' flag is also passed.`}</p>
    <p><inlineCode parentName="p">{`algorithm`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`HS256`}</strong>{`: HMAC using SHA-256 (default for "oct" key type)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`HS384`}</strong>{`: HMAC using SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`HS512`}</strong>{`: HMAC using SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RS256`}</strong>{`: RSASSA-PKCS1-v1_5 using SHA-256 (default for "RSA" key type)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RS384`}</strong>{`: RSASSA-PKCS1-v1_5 using SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RS512`}</strong>{`: RSASSA-PKCS1-v1_5 using SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ES256`}</strong>{`: ECDSA using P-256 and SHA-256 (default for "EC" key type)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ES384`}</strong>{`: ECDSA using P-384 and SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ES512`}</strong>{`: ECDSA using P-521 and SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PS256`}</strong>{`: RSASSA-PSS using SHA-256 and MGF1 with SHA-256`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PS384`}</strong>{`: RSASSA-PSS using SHA-384 and MGF1 with SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PS512`}</strong>{`: RSASSA-PSS using SHA-512 and MGF1 with SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`EdDSA`}</strong>{`: EdDSA signature algorithm`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--jku`}</strong>{`=`}<inlineCode parentName="p">{`jwk-url`}</inlineCode>{`
The "jku" (JWK Set URL) Header Parameter is a URI that refers to a resource
for a set of JSON-encoded public keys, one of which corresponds to the key
used to digitally sign the JWS. The keys MUST be encoded as a JWK Set (JWK).
The protocol used to acquire the resource MUST provide integrity protection;
an HTTP GET request to retrieve the JWK Set MUST use Transport Layer Security
(TLS); and the identity of the server MUST be validated. Use of `}<inlineCode parentName="p">{`jwk-url`}</inlineCode>{` is
optional.`}</p>
    <p><strong parentName="p">{`--jwk`}</strong>{`=`}<inlineCode parentName="p">{`jwk`}</inlineCode>{`
The "jwk" (JSON Web Key) Header Parameter is the public key that corresponds
to the key used to digitally sign the JWS. This key is represented as a JSON
Web Key (JWK). Use of `}<inlineCode parentName="p">{`jwk`}</inlineCode>{` is optional.`}</p>
    <p><strong parentName="p">{`--typ`}</strong>{`=`}<inlineCode parentName="p">{`type`}</inlineCode>{`, `}<strong parentName="p">{`--type`}</strong>{`=`}<inlineCode parentName="p">{`type`}</inlineCode>{`
The "typ" (type) Header Parameter is used by JWS applications to declare the
media type of this complete JWS. This is intended for use by the application
when more than one kind of object could be present in an application data
structure that can contain a JWS; the application can use this value to
disambiguate among the different kinds of objects that might be present. It
will typically not be used by applications when the kind of object is already
known. This parameter is ignored by JWS implementations; any processing of
this parameter is performed by the JWS application. Use of `}<inlineCode parentName="p">{`type`}</inlineCode>{` is
optional.`}</p>
    <p>{`The "typ" value "JOSE" can be used by applications to indicate that this
object is a JWS or JWE using the JWS Compact Serialization or the JWE Compact
Serialization. The "typ" value "JOSE+JSON" can be used by applications to
indicate that this object is a JWS or JWE using the JWS JSON Serialization or
the JWE JSON Serialization. Other type values can also be used by
applications.`}</p>
    <p><strong parentName="p">{`--cty`}</strong>{`=`}<inlineCode parentName="p">{`content-type`}</inlineCode>{`
The "cty" (content type) Header Parameter is used by JWS applications to
declare the media type of the secured content (the payload). This is intended
for use by the application when more than one kind of object could be present
in the JWS Payload; the application can use this value to disambiguate among
the different kinds of objects that might be present. It will typically not be
used by applications when the kind of object is already known. This parameter
is ignored by JWS implementations; any processing of this parameter is
performed by the JWS application. Use of `}<inlineCode parentName="p">{`content-type`}</inlineCode>{` is optional.`}</p>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--x5c-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--x5t-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the key with which to sign the JWS.
JWSs can be signed using a private JWK (or a JWK encrypted as a JWE payload) or
a PEM encoded private key (or a private key encrypted using the modes described
on RFC 1423 or with PBES2+PBKDF2 described in RFC 2898).`}</p>
    <p><strong parentName="p">{`--jwks`}</strong>{`=`}<inlineCode parentName="p">{`jwks`}</inlineCode>{`
The JWK Set containing the key to use to sign the JWS. The `}<inlineCode parentName="p">{`jwks`}</inlineCode>{` argument
should be the name of a file. The file contents should be a JWK Set or a JWE
with a JWK Set payload. The `}<strong parentName="p">{`--jwks`}</strong>{` flag requires the use of the `}<strong parentName="p">{`--kid`}</strong>{`
flag to specify which key to use.`}</p>
    <p><strong parentName="p">{`--kid`}</strong>{`=`}<inlineCode parentName="p">{`kid`}</inlineCode>{`
The ID of the key used to sign the JWS. The `}<inlineCode parentName="p">{`kid`}</inlineCode>{` argument is a case-sensitive
string. When used with '--jwk' the `}<inlineCode parentName="p">{`kid`}</inlineCode>{` value must match the `}<strong parentName="p">{`"kid"`}</strong>{` member
of the JWK. When used with `}<strong parentName="p">{`--jwks`}</strong>{` (a JWK Set) the `}<inlineCode parentName="p">{`kid`}</inlineCode>{` value must match
the `}<strong parentName="p">{`"kid"`}</strong>{` member of one of the JWKs in the JWK Set.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt or decrypt the private key.`}</p>
    <p><strong parentName="p">{`--x5c-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'x5c' header of a JWT.`}</p>
    <p><strong parentName="p">{`--x5t-cert`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Certificate `}<inlineCode parentName="p">{`file`}</inlineCode>{` in PEM format to use for the 'x5t' header of a JWS or JWT`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      